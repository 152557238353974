/*
 * @Description: axios to property
 * @Author: ls
 * @Date: 2019-10-17 10:05:03
 * @LastEditTime: 2020-04-21 09:05:43
 * @LastEditors: Please set LastEditors
 */
import axios from "axios";
import { Loading, Message, MessageBox } from "element-ui";
import api from "./api";
import Vue from "vue";


var loadingTimeOut
var loading; //加载变量
function startLoading() {
    // if(!loadingTimeOut){
    //     loadingTimeOut = setTimeout(function(){
    //         loading?'':loading = Loading.service({
    //             text: "拼命加载中~",
    //             background: "rgba(0,0,0,0.7)"
    //         });
    //     },500)
    // }else{
    // }
}

function endLoading() {
    // clearTimeout(loadingTimeOut)
    // loadingTimeOut = ''
    // loading?(loading.close(),loading = ''):''
}

function getCookie(name) {
    var matches = document.cookie.match(
      new RegExp("(?:^|; )" + name + "=([^;]*)")
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }


// 全局默认配置
// axios.defaults.headers.common["Path"] = "Hr";
// axios.defaults.withCredentials = true;

// 局部实例默认值
var admin = axios.create({});
admin.defaults.headers.common["Path"] = "Admin";
axios.defaults.headers.common["Path"] = "Hr";
axios.defaults.headers.common["Authorization"] = `Bearer ${getCookie('tokenAdmin')}`;
axios.defaults.withCredentials = false;
// 请求拦截
axios.interceptors.request.use(
    (config) => {
        startLoading();
        // key有treeData的时候分成departmentID
        let treeData = config.params && config.params.treeData;
        if (treeData) {
            treeData = treeData.split("|");
            const departmentID = [];
            const companyID = [];
            var company = localStorage.getItem("companyID").split(",");
            treeData.forEach((item) => {
                company.includes(item)
                    ? companyID.push(item)
                    : departmentID.push(item);
            });
            delete config.params.treeData;
            config.params.companyID = companyID.join("|");
            config.params.departmentID = departmentID.join("|");
        } else if (treeData == "") {
            config.params &&
                config.params.companyID &&
                (config.params.companyID = "");
            config.params &&
                config.params.departmentID &&
                (config.params.departmentID = "");
        }

        return config;
    },
    (error) => {
        if (!error.response) {
            alert("网络错误");
        } else if (error.response.status == 301) {
            window.location.href = homeApiHr;
        } else if (error.response.status == 401) {
            alert("请求错误");
        } else if (error.response.status == 404) {
            alert("请求失败！");
        } else if (error.response.status == 408) {
            alert("请求超时！");
        } else if (error.response.status == 500) {
            alert("未知的错误！");
        } else if (error.response.status == 500) {
            alert("服务器不支持！");
        } else {
            alert("请求异常！");
        }
    }
);

// 响应拦截
axios.interceptors.response.use(
    (res) => {
        const data = res.data;
        if (data.Result != null && !data.Result)
            Message({
                message: data.PromptMsg,
                
                type: "warning",
            });
        endLoading()
        return res;
    },
    (err) => {
        console.log(err);
        if (err.response) {
            switch (err.response.status) {
                case 401:
                    if (err.response.config.url.includes("/CandidatePublic/")) {
                        // Message({
                        //     type:'error',
                        //     message:'链接已过期，请联系HR重新获取链接'
                        // })
                        alert("链接已过期，请联系HR重新获取链接");
                    } else {
                        top.location.href = api.homeApi + "/Login/HrLogin";
                    }
                    break;
                case 301:
                    top.location.href = "http://ErsApiqa.1renshi.com/";
                    break;
            }
        }
        return Promise.reject(err);
    }
);

// isAdmin为true的时候，使用admin实例
const axiosGet = function(url, parmas, isAdmin = false) {
    var request = isAdmin ? admin : axios;
    return new Promise((resolve) => {
        request
            .get(url, {
                params: parmas,
            })
            .then(({ data }) => {
                resolve(data);
            });
    });
};

const axiosBlobGet = function(url, parmas, isAdmin = false) {
    var request = isAdmin ? admin : axios;
    return new Promise((resolve) => {
        request
            .get(url, {
                params: parmas,
                responseType: "blob",
            })
            .then(({ data }) => {
                resolve(data);
            });
    });
};

const axiosPost = function(url,data, params, isAdmin = false) {
    var request = isAdmin ? admin : axios;
    return new Promise((resolve) => {
        request
            .post(url, data, {
                params,
            })
            .then(({ data }) => resolve(data));
    });
};

const axiosPut = function(url, parmas, data, isAdmin = false) {
    var request = isAdmin ? admin : axios;
    return new Promise((resolve) => {
        request
            .put(url, parmas, {
                data,
            })
            .then(({ data }) => resolve(data));
    });
};

const axiosComPut = function(url, params, data, isAdmin = false) {
    var request = isAdmin ? admin : axios;
    return new Promise((resolve) => {
        request
            .request({
                url: url,
                method: "put",
                data: data,
                parmas: params,
            })
            .then(({ data }) => resolve(data));
    });
};

const axiosHaveFile = function(url, params, data, isAdmin = false) {
    var request = isAdmin ? admin : axios;
    return new Promise((resolve) => {
        request
            .post(url, params, {
                "Content-Type": "multipart/form-data",
            })
            .then(({ data }) => resolve(data));
    });
};

const axiosDelete = function(url, params, data, isAdmin = false) {
    var request = isAdmin ? admin : axios;
    return new Promise((resolve) => {
        request
            .request({
                url,
                method: "delete",
                data: data,
                params: params,
            })
            .then(({ data }) => resolve(data));
    });
};

// 暴露axios
export default {
    axios,
    axiosGet,
    axiosPost,
    api,
    axiosPut,
    axiosComPut,
    axiosHaveFile,
    axiosDelete,
    axiosBlobGet,
};
