<template>
  <div id="app">
    <keep-alive :exclude="unloadurl">
      <!-- <Header></Header> -->
      <router-view v-if="isRouterAlive"></router-view>
    </keep-alive>
    
    <!-- <router-view v-if="isRouterAlive==true&&isAnswerAD==true"></router-view> -->
  </div>
</template>
<script>
import Header from "./components/Header";
export default {
  components:{
    Header,
  },
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      unloadurl:['Aboutques','ListEdit','Feedpage','LabelAddArticle'],
      noRefreshPage:['Qadministration','Comment','ArticleManage','Aboutques','ListEdit','Feedpage','aboutquestion','feedbackpage','Hotspotlabel','LabelAddArticle']
    };
  },
  created(){
    this.$router.beforeEach((to, from, next) => {
      //console.log(to.name,from.name);
      if(to.name !== from.name){
        // console.log(to.name,from.name)
        //this.$router.go(0)
        if(this.noRefreshPage.includes(to.name)){
          // console.log(to.name,from.name)
        }else{
          //console.log(1)
          this.$router.go(0)
        }
      }
      next();
    });
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="stylus">
#app{
  height 100%
  // height 100vh
  // min-width 1250px
}
</style>
