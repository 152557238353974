const ApiHost = process.env.VUE_APP_ApiHost;
const ApiBus = process.env.VUE_APP_ApiBus;
const ApiCommunity = process.env.VUE_APP_ApiCommunity;


export default {
  GetList: ApiHost + "/api/Banner/GetList", //获取分页
  UpdateModel: ApiHost + "/api/Banner/UpdateModel", //修改
  AddModel: ApiHost + "/api/Banner/AddModel", //新增
  UpdateSortnumber: ApiHost + "/api/Banner/UpdateSortnumber", //修改序列号
  DeleteModel: ApiHost + "/api/Banner/DeleteModel", //删除

  //类别
  AddArticleCategory: ApiHost + "/api/ArticleCategory/AddArticleCategory", //添加类别
  UpdateArticleCategory: ApiHost + "/api/ArticleCategory/UpdateArticleCategory", //修改类别
  GetArticleCategoryList: ApiHost + "/api/ArticleCategory/GetArticleCategoryList", //获取类别分页
  // GetAllArticleCategory: ApiHost + "/api/ArticleCategory/GetArticleCategoryData?type=0", //获取所有类别
  GetAllArticleCategory:ApiHost+"/api/ArticleCategory/GetArticleCategoryDataWithChildren?type=0",//获取所有类别（新）
  DeleteArticleCategory: ApiHost + "/api/ArticleCategory/DeleteArticleCategory", //删除类别

  EditionAddModel: ApiHost + "/api/EditionManagement/AddModel", //新增版本记录
  EditionDeleteModel: ApiHost + "/api/EditionManagement/DeleteModel", //删除版本记录
  EditionGetList: ApiHost + "/api/EditionManagement/GetList", //获取版本记录分页
  EditionGetModel: ApiHost + "​/api​/EditionManagement​/GetModel", //根据id查看版本详情
  EditWebSideArticle: ApiHost + "/api/Article/EditWebSideArticle", //文章页面侧边栏文章管理修改

  GetArticleDTO: ApiHost + "/api/Article/GetArticleDTO", //根据id获取文章详情
  GetQuestionContainRelatedQuestionsByH5: ApiHost + "/api/HelpCenterQuestion/GetQuestionContainRelatedQuestionsByH5", //获取问题详情包含相关问题

  //热点标签api
  AddHotLabel: ApiHost + "/api/HotLabel/AddHotLabel", //添加热点标签
  SearchHotLabel: ApiHost + "/api/HotLabel/SearchHotLabel", //查询分页
  UpdateHotLabel: ApiHost + "/api/HotLabel/UpdateHotLabel", //修改热点标签
  EnableHotLabel: ApiHost + "/api/HotLabel/EnableHotLabel", //设置线上状态
  OverheadHotLabel: ApiHost + "/api/HotLabel/OverheadHotLabel", //设置热点标签置顶
  GetAllHotLabel: ApiHost + "/api/HotLabel/GetAllHotLabel", //获取热点标签
  ImportHotLabel:ApiHost+"/api/HotLabel/ImportHotLabel",//获取热点标签导入模板
  GetNoneLabelArtcle:ApiHost+"/api/Article/GetArticleNoHotLabelPage",//获取没有添加热点标签的所有文章
  SetArticlesHotLabel:ApiHost+"/api/Article/SetArticlesHotLabel",//批量设置文章热点标签

  //友情链接api
  AddLink: ApiHost + "/api/Links/AddLink", //添加友联
  UpdateLink: ApiHost + "/api/Links/UpdateLink",
  GetLinkList: ApiHost + "/api/Links/GetLinkList", //返回友链
  DeleteLink: ApiHost + "/api/Links/DeleteLink", //删除友链
  EnableLink: ApiHost + "/api/Links/EnableLink",

  //文章管理
  GetArticleList:ApiHost+"/api/Article/GetArticleList",//获取文章分页列表
  SearchArticleByArticleId: ApiHost + "/api/Article/SearchArticleByArticleId", //Admin端文章侧边栏管理获取文章类表单页数据
  GetArticle: ApiHost + "/api/Article/GetArticle", //根据id获取文章详情  
  NewOrEditArticle: ApiHost + "/api/Article/NewOrEditArticle", //新增 编辑
  DeleteArticle: ApiHost + "/api/Article/DeleteArticle", //删除
  CancleRecommend: ApiHost + "/api/Article/CancleRecommend", //取消推荐
  GetCityDropDownList: ApiHost + "/api/Article/GetCityDropDownList", //获取城市
  EditWebSideArticleCategory: ApiHost + "/api/Article/EditWebSideArticleCategory", //编辑图片序号
  GetArticleCategoryData: ApiHost + "/api/ArticleCategory/GetArticleCategoryData?type=1", //获取类别名称
  GetArticalIdList: ApiHost + "/api/Article/GetArticalIdList", //获取选中的文章 管理端侧边栏配置文章使用
  GetTopicList: ApiHost + "/api/TopicCategory/GetTopicList", //获取帮助中心分类
  GetTopicType:ApiCommunity+"/api/TopicCategory/GetTopicList",//获取社区话题分类
  IsPCArticle:ApiCommunity+"/api/TopicArticle/IsPCArticle",//是否同步社区
  AddArticleList:ApiCommunity+"/api/TopicArticle/AddArticleList",//同步社区文章

  //帮助中心
  //分类
  GetHelpCenterCategoryPageList: ApiHost + "/api/HelpCenterCategory/GetHelpCenterCategoryPageList", //话题管理分页（admin端）
  GetAllTopHelpCenterCategor: ApiHost + "/api/HelpCenterCategory/GetAllTopHelpCenterCategor", //获取所有的顶层分类（admin端、app端、后端）
  GetNextHelpCategorsByParentId: ApiHost + "/api/HelpCenterCategory/GetNextHelpCategorsByParentId", //d通过父级分类获取子级分类(admin端、app端、PC端)
  ResetEnableFlag: ApiHost + "/api/HelpCenterCategory/ResetEnableFlag", //修改分类是否启用（admin端）
  AddCategory: ApiHost + "/api/HelpCenterCategory/AddCategory", //添加分类（Admin端）
  EditCategory: ApiHost + "/api/HelpCenterCategory/EditCategory", //修改分类（Admin端）
  DeleteCategory: ApiHost + "/api/HelpCenterCategory/DeleteCategory", //删除分类(admin端)

  //新增问题
  GetAdminQuestionList: ApiHost + "/api/HelpCenterQuestion/GetAdminQuestionList", //分页查询服务(admin端)
  GetQuestionDetailById: ApiHost + "/api/HelpCenterQuestion/GetQuestionDetailById", //获取问题详情（app端、admin端）
  ResetHotFlag: ApiHost + "/api/HelpCenterQuestion/ResetHotFlag", //是否将方法设置成热门（admin）
  AddUseOrUnUseCount: ApiHost + "/api/HelpCenterQuestion/AddUseOrUnUseCount", //反馈问题是否有帮助
  AddQuestion: ApiHost + "/api/HelpCenterQuestion/AddQuestion", //添加问题（admin）
  EditQuestion: ApiHost + "/api/HelpCenterQuestion/EditQuestion", //修改问题（admin）
  GetReplyPageListByQuestionId: ApiHost + "/api/HelpCenterQuestion/GetReplyPageListByQuestionId", //获取反馈分页（admin端）
  ConfigurateRelatedQuestion: ApiHost + "/api/HelpCenterQuestion/ConfigurateRelatedQuestion", //提交
  GetAdminQuestionList: ApiHost + "/api/HelpCenterQuestion/GetAdminQuestionList", //获取配置

  //热门词组管理
  GetHotWordPageList: ApiHost + "/api/HelpCenterQuestion/GetHotWordPageList", //热词搜索分页(admin端)
  AddHotWord: ApiHost + "/api/HelpCenterQuestion/AddHotWord", //添加热词（admin端）
  UpdateHotWord: ApiHost + "/api/HelpCenterQuestion/UpdateHotWord", //修改热词（admin端）
  DeleteHotWord: ApiHost + "/api/HelpCenterQuestion/DeleteHotWord", //删除热词（admin端）
  ReSetEnableHotWord: ApiHost + "/api/HelpCenterQuestion/ReSetEnableHotWord", //设置热词可用性（admin端）

  //固定区域
  GetFixedContentByType: ApiHost + "/api/HelpCenterQuestion/GetFixedContentByType", //查询固定区域内容
  EditFixedContent: ApiHost + "/api/HelpCenterQuestion/EditFixedContent", //编辑

  //新手学堂
  SetDisplayQuestionInNoviceSchool: ApiHost + "/api/HelpCenterQuestion/SetDisplayQuestionInNoviceSchool", //设置在新手学堂显示
  //帮助文档管理
  GetNoviceSchoolArticlePage: ApiHost + "/api/Article/GetNoviceSchoolArticlePage", //获取"新手学堂"帮助文档分页数据(admin)
  UpdateArticleSortNumber: ApiHost + "/api/Article/UpdateArticleSortNumber", //"新手学堂"修改排序方法(Admin)
  //视频管理
  GetBeginnerSchoolCategoryPageList: ApiHost + "/api/HelpCenterCategory/GetBeginnerSchoolCategoryPageList", //新手学堂话题管理分页（admin端）
  ResetBeginnerSchoolEnableFlag: ApiHost + "/api/HelpCenterCategory/ResetBeginnerSchoolEnableFlag", //新手学堂修改分类是否显示（admin端）
  EditBeginnerSchoolCategory: ApiHost + "/api/HelpCenterCategory/EditBeginnerSchoolCategory", //新手学堂视频修改分类（Admin端）
  AddBeginnerSchoolCategory: ApiHost + "/api/HelpCenterCategory/AddBeginnerSchoolCategory", //新手学堂添加分类（Admin端）
  GetAdminVideoList: ApiHost + "/api/HelpCenterQuestion/GetAdminVideoList", //视频管理分页接口
  AddVideo: ApiHost + "/api/HelpCenterQuestion/AddVideo", //添加视频
  EditVideo: ApiHost + "/api/HelpCenterQuestion/EditVideo", //修改视频
  ResetVideoDisplay: ApiHost + "/api/HelpCenterQuestion/ResetVideoDisplay", //显示、隐藏视频
  GetAllVideoType: ApiHost + "/api/HelpCenterCategory/GetAllBeginnerSchoolCategoryName", //获取视频分类

  //帮助中心/新手学堂  删除、是否显示、是否热门、是否在新手学堂显示
  HelpCenterOperate: ApiHost + "/api/HelpCenterQuestion/HelpCenterOperate",

  //周刊订阅
  WeeklyGetList:ApiHost+"/api/WeeklyManagement/GetList",

  // 广告位
  GetAppAdsensesList:ApiHost+"/api/AdSense/GetAppAdsenses",
  DeleteAdSense:ApiHost+"/api/AdSense/DeleteAdSense",
  UploadFileV2:ApiBus+"/Common/UploadFile/V2",

  //客户案例管理
  GetSelectItems: ApiHost + "/api/CustomerCase/GetParamsAlternative", //获取产品、行业、规模下拉数据
  SearchCustomerCases: ApiHost + "/api/CustomerCase/GetCustomerCaseListAdmin", //查询分页
  AddCustomerCase: ApiHost + "/api/CustomerCase/AddCustomerCase", //新增客户案例
  EditCustomerCase: ApiHost + "/api/CustomerCase/EditCustomerCase", //修改客户案例
  DeleteCustomerCase: ApiHost + "/api/CustomerCase/DeleteCustomerCase", //删除客户案例
}
