<template>
  <div class="home">
    <el-container>
      <el-header>
        <div>
          <i></i>
          <h2>易人事管理系统</h2>
        </div>
      </el-header>
      <el-container>
        <el-aside width="250px">
          <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
          >
            <el-submenu index="1" v-if="false">
              <template slot="title">
                <i class="el-icon-setting"></i>
                <span>系统管理</span>
              </template>
              <el-menu-item index="1-4-1"
                ><li><span>选项1</span></li></el-menu-item
              >
              <el-menu-item index="1-4-2">选项1</el-menu-item>
              <el-menu-item index="1-4-3">选项1</el-menu-item>
              <el-menu-item index="1-4-4">选项1</el-menu-item>
              <el-menu-item index="1-4-5">选项1</el-menu-item>
              <el-menu-item index="1-4-6">选项1</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span slot="title">前端管理</span>
              </template>
              <el-menu-item
                v-for="(item, index) in secondmenu"
                :key="index"
                :class="index"
              >
                <router-link class="click" :to="`/${item.src}`" tag="li"
                  ><span class="choose">{{ item.name }}</span></router-link
                >
                <!-- {{item.name}} -->
              </el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-help"></i>
                <span slot="title">帮助中心管理</span>
              </template>
            <el-menu-item
                v-for="(item, index) in helpcenter"
                :key="index"
                :class="index"
              >
                <router-link class="click" :to="`/${item.src}`" tag="li"
                  ><span class="choose">{{ item.name }}</span></router-link
                >
                <!-- {{item.name}} -->
              </el-menu-item>
            </el-submenu>
             <el-submenu index="4">
              <template slot="title">
                <i class="el-icon-school"></i>
                <span slot="title">新手学堂管理</span>
              </template>
            <el-menu-item
                v-for="(item, index) in noviceschool"
                :key="index"
                :class="index"
              >
                <router-link class="click" :to="`/${item.src}`" tag="li"
                  ><span class="choose">{{ item.name }}</span></router-link
                >
                <!-- {{item.name}} -->
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="5" v-if="false">
              <i class="el-icon-setting"></i>
              <span slot="title">退出</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view tag="div" class="link"></router-view>

          <!-- <keep-alive>
            <router-view tag="div" class="link"></router-view>
          </keep-alive> -->
          <!-- <router-view tag="div" class="link"></router-view> -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      secondmenu: [
        {
          id: "",
          name: "友情链接管理",
          src: "Friendlinks",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "客户案例管理",
          src: "CustomerCases",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "广告位管理",
          src: "Advertising",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "文章分类管理",
          src: "Articletype",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "页面文章管理",
          src: "Article",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "热点标签管理",
          src: "Hotspotlabel",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "版本管理",
          src: "Version",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "文章列表",
          src: "ArticleManage",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "热门文章",
          src: "HotArticle",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "特定位置热门文章配置",
          src: "Designated",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "固定区域管理",
          src: "positionad",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "周刊订阅",
          src: "WeeklySubscription",
          switch: 0,
          tab: 0,
          key: 0,
        },
      ],
      noviceschool:[
        {
          id: "",
          name: "视频类型管理",
          src: "VideoType",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "视频管理",
          src: "VideoManagement",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "帮助文档管理",
          src: "HelpDocumentation",
          switch: 0,
          tab: 0,
          key: 0,
        },
      ],
      helpcenter:[
         {
          id: "",
          name: "分类管理",
          src: "Classification",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "问题管理",
          src: "Qadministration",
          switch: 0,
          tab: 0,
          key: 0,
        },
         {
          id: "",
          name: "反馈页",
          src: "feedbackpage",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "相关问题配置",
          src: "aboutquestion",
          switch: 0,
          tab: 0,
          key: 0,
        },
        {
          id: "",
          name: "热门词组管理",
          src: "hotwordad",
          switch: 0,
          tab: 0,
          key: 0,
        },
      ]
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang="stylus" scoped>
.home {
  // height: 100%;
  .el-header {
    background: #409EFF;

    h2 {
      color: #fff;
      padding: 15px 20px;
      text-align: left;
    }
  }

  .el-aside {
    overflow-x: hidden;
    overflow-y: hidden;
    ul.el-menu {
      /deep/ li:hover {
        color: #409eff !important;
      }

      border-right: 0px;

      span.choose {
        padding-left: 50px;
      }
    }

    // height: 100%;
    min-height: calc(100vh - 60px);
    background: #fff;

    .click {
      display: inline-block;
      width: 100%;
      height: 50px;
    }

    .el-submenu .el-menu-item {
      padding: 0;
    }

    /deep/.el-menu-item {
      padding: 0 !important;
    }
  }

  .el-mian {
    background: #fff;
  }

  /deep/section.el-container {
    height: 100%;
  }
}

.router-link-active {
  color: #409eff;
  background: #ECF5FF;
}
</style>