import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueDND from 'awe-dnd';
import _ from "lodash";
import axios from './js/axios'
import Directives from './utils/derectives';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css'

const Bus = new Vue();

Vue.use(ElementUI);
Vue.use(VueDND);
Vue.use(Directives);
// Vue.use(Sortable);

Vue.config.productionTip = false

Vue.prototype.$axios = axios.axios;
Vue.prototype.$get = axios.axiosGet;
Vue.prototype.$blobGet = axios.axiosBlobGet;
Vue.prototype.$post = axios.axiosPost;
Vue.prototype.$api = axios.api;
// Vue.prototype.$adminApi = axios.adminApi;
Vue.prototype.$put = axios.axiosPut;
Vue.prototype.$comPut = axios.axiosComPut;
Vue.prototype.$haveFile = axios.axiosHaveFile;
Vue.prototype.$delete = axios.axiosDelete;
Vue.prototype._ = _;
// Vue.prototype.router=router;

new Vue({
  router,
  store,
  render: h => h(App),
  data() {
    return {
      Bus      //很重要----------------------
    }
  },
}).$mount('#app')



Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})