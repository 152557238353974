import Vue from 'vue'
import VueRouter from 'vue-router'

const Advertising = resolve => require(["../views/Front/Advertising.vue"], resolve);//广告位管理
const Articletype = resolve => require(["../views/Front/Article-type.vue"], resolve);//文章分类管理
const Article = resolve => require(["../views/Front/Article.vue"], resolve);//页面文章管理
const Friendlinks = resolve => require(["../views/Front/Friend-links.vue"], resolve);// 友情链接配置
const CustomerCases = resolve => require(["../views/Front/Customer-cases.vue"], resolve);// 客户案例管理
const Hotspotlabel = resolve => require(["../views/Front/Hotspot-label.vue"], resolve);//热点标签管理
const Version = resolve => require(["../views/System/Version.vue"], resolve);//版本管理
const articleDetail = resolve => require(["../views/news/articleDetail.vue"], resolve); // APP 文章详情页
const questionDetails = resolve => require(["../views/news/questionDetails.vue"], resolve); // APP 帮助中心问题详情页
const ArticleManage = resolve => require(["../views/Front/ArticleManage.vue"], resolve);//文章列表
const ListEdit = resolve => require(["../views/Front/Aticle-list-edit/List-edit.vue"], resolve);// 文章列表 文章编辑页面
const HotArticle = resolve => require(["../views/Front/Hot-article.vue"], resolve);//热门文章
const Articleedit = resolve => require(["../views/Front/Aticle-list-edit/Article-edit.vue"], resolve);// 热门文章 配置详细页侧边栏
const Designated = resolve => require(["../views/Front/Designated-page.vue"], resolve);//特定位置热门文章配置
const LabelAddArticle = resolve => require(["../views/Front/HotLabel/addArticle.vue"], resolve);//热点标签 添加文章

//帮助中心
const Classification = resolve => require(["../views/helpcenter/Classification.vue"], resolve);//分类管理
const Qadministration = resolve => require(["../views/helpcenter/Qadministration.vue"], resolve);//问题管理
const aboutquestion = resolve => require(["../views/helpcenter/aboutquestion.vue"], resolve);//问题管理 相关问题配置
const feedbackpage = resolve => require(["../views/helpcenter/feedbackpage.vue"], resolve);//问题管理 反馈页
const hotwordad = resolve => require(["../views/helpcenter/hotwordad.vue"], resolve);//热门词组管理
const positionad = resolve => require(["../views/helpcenter/positionad.vue"], resolve);//固定区域管理

//新手学堂
const VideoType = resolve => require(["../views/noviceschool/VideoType.vue"], resolve);//视频类型管理
const VideoManagement = resolve => require(["../views/noviceschool/VideoManagement.vue"], resolve);//视频管理
const HelpDocumentation = resolve => require(["../views/noviceschool/HelpDocumentation.vue"], resolve);//帮助文档管理
//周刊订阅
const WeeklySubscription = resolve => require(["../views/admin/WeeklySubscription.vue"],resolve);//周刊订阅

Vue.use(VueRouter)
export default new VueRouter({
  routes: [
    { path: '/Advertising', name: 'Advertising', component: Advertising },
    { path: '/Articletype', name: 'Articletype', component: Articletype },
    { path: '/Article', name: 'Article', component: Article },
    { path: '/Friendlinks', name: 'Friendlinks', component: Friendlinks },
    { path: '/CustomerCases', name: 'CustomerCases', component: CustomerCases },
    { path: '/Hotspotlabel', name: 'Hotspotlabel', component: Hotspotlabel },
    { path: '/Version', name: 'Version', component: Version },
    { path: '/articleDetail', name: 'articleDetail', component: articleDetail },
    { path: '/questionDetails', name: 'questionDetails', component: questionDetails },
    { path: '/ArticleManage', name: 'ArticleManage', component: ArticleManage },
    { path: '/ListEdit', name: 'ListEdit', component: ListEdit },
    { path: '/HotArticle', name: 'HotArticle', component: HotArticle },
    { path: '/Articleedit', name: 'Articleedit', component: Articleedit },
    { path: '/Designated', name: 'Designated', component: Designated },
    { path: '/Comment', name: 'Comment', component: Comment },
    { path: '/LabelAddArticle', name: 'LabelAddArticle', component: LabelAddArticle },

    //帮助中心
    { path: '/Classification', name: 'Classification', component: Classification },   
    { path: '/aboutquestion', name: 'aboutquestion', component: aboutquestion },
    { path: '/feedbackpage', name: 'feedbackpage', component: feedbackpage },
    { path: '/hotwordad', name: 'hotwordad', component: hotwordad },
    { path: '/positionad', name: 'positionad', component: positionad },
    { path: '/Qadministration', name: 'Qadministration', component: Qadministration },

    //新手学堂
    { path: '/VideoType', name: 'VideoType', component: VideoType },
    { path: '/VideoManagement', name: 'VideoManagement', component: VideoManagement },
    { path: '/HelpDocumentation', name: 'HelpDocumentation', component: HelpDocumentation },

    //周刊订阅
    { path:'/WeeklySubscription',name:'WeeklySubscription',component:WeeklySubscription},
  ]
});


